// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-facilities-jsx": () => import("./../../../src/pages/facilities.jsx" /* webpackChunkName: "component---src-pages-facilities-jsx" */),
  "component---src-pages-forms-jsx": () => import("./../../../src/pages/forms.jsx" /* webpackChunkName: "component---src-pages-forms-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-medical-history-questionnaire-jsx": () => import("./../../../src/pages/medical-history-questionnaire.jsx" /* webpackChunkName: "component---src-pages-medical-history-questionnaire-jsx" */),
  "component---src-pages-new-patient-form-jsx": () => import("./../../../src/pages/new-patient-form.jsx" /* webpackChunkName: "component---src-pages-new-patient-form-jsx" */),
  "component---src-pages-procedures-jsx": () => import("./../../../src/pages/procedures.jsx" /* webpackChunkName: "component---src-pages-procedures-jsx" */),
  "component---src-pages-referral-jsx": () => import("./../../../src/pages/referral.jsx" /* webpackChunkName: "component---src-pages-referral-jsx" */),
  "component---src-templates-sub-procedure-jsx": () => import("./../../../src/templates/subProcedure.jsx" /* webpackChunkName: "component---src-templates-sub-procedure-jsx" */)
}

